.grid-container {
  display: grid;
}

.main {
  grid-column: 2;
  grid-row: 1;
}

.flop-left {
  grid-column: 1;
  grid-row: 1;
}

.flop-right {
  grid-column: 3;
  grid-row: 1;
}

.kewlImage {
  width: 374px;
  height: 80%;
}

.pozzed{
  position:fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  width:100%;
}

.pozzedGif{
  width:50%;
  height:15%;
}