p{
    color:rgb(53, 223, 235);
    text-align: center;
    font-size: 40px;
    margin: 0px;  
}

.head {
    font-weight: bold;
    font-size: 70px;
    margin-top: 0%;
}

.fan-message {
    color: rgb(255, 0, 191);
    font-style: oblique;
    text-align: center;
    font-size: 40px;
    margin: 0px;
}
  